<template>

  <v-app :dark='$vuetify.theme.dark'>
    
    <!-- Side Navigation (SideNav) -->
    <v-navigation-drawer touchless
                         :clipped="$vuetify.breakpoint.lgAndUp"
                         app
                         v-model="drawer"
                         width="260"
                         :class="{'dark-mode':$store.state.NightMode.sideNav == 'dark-mode','light-mode':$store.state.NightMode.sideNav == 'light-mode'}"
                         text>
      <SideNav></SideNav>
    </v-navigation-drawer>
    <!-- /End Of Side Nav -->

    <!-- Top Nav -->
    <v-app-bar flat
               app
               :clipped-left="$vuetify.breakpoint.lgAndUp"
               fixed
               dense
               :class="{'dark-mode':$store.state.NightMode.topNav == 'dark-mode','light-mode':$store.state.NightMode.topNav == 'light-mode', 'topnav': 'topnav'}">

        <v-toolbar-title style="width: 260px" class="ml-0">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-sm-and-down"></v-app-bar-nav-icon>
            <span> <span style="color: orange;">MyWallet</span>Watcher</span>
        </v-toolbar-title>


        <v-spacer></v-spacer>

        <!-- Quick launch-->
        <!--<v-menu offset-y
            left
            :close-on-content-click="false">
      <v-btn icon slot="activator" class="b-3">
        <v-icon>apps</v-icon>
      </v-btn>
      <v-card app>
        <v-toolbar dense text>
          <v-toolbar-title>
            <span> Quick launch </span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon text>
            <v-icon>fa-cog</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container fluid grid-list-xs class="noPadding">

          <v-layout row wrap>
            <v-flex v-for="item in quicklaunch" :key="item.subtitle" xs3>
              <v-list>
                <v-list-item height="auto" width="100%" align-center @click="" ripple>
                  <v-list-item-content class="layout justify-center text-xs-center">
                    <v-list-item-title>
                      <v-icon :class="[item.iconClass]"> {{ item.icon }} </v-icon>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>

    </v-menu>-->
        <!-- /quick launch -->
        <!-- notifications -->
        <!--<v-menu :close-on-content-click="true"
            left
            offset-y>
      <v-btn icon slot="activator">
        <v-badge color="red" overlap>
          <span slot="badge" v-if="notificationBadge !== ''"> {{ notificationBadge }} </span>
          <v-icon>notifications</v-icon>
        </v-badge>
      </v-btn>
      <v-card app>
        <v-toolbar dense text>
          <v-toolbar-title>
            <span> Notifications</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn icon text @click="$router.push({ path: '/dashboard/settings/notification' })">
            <v-icon>fa-cog</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container fluid class="noPadding medium_container">
          <v-list two-line>
            <v-list two-line subheader>

              <v-list-item v-for="item in recent_notifications" :key="item.title" @click="">
                <v-list-item-avatar>
                  <v-icon :class="[item.iconClass]">{{ item.icon }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon ripple>
                    <v-icon color="grey lighten-1">close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-list>
        </v-container>
        <v-card-actions>
          <v-btn block text @click="$router.push({ path: '/dashboard/notifications' })">
            view all
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>-->
        <!-- notifications -->
        <!-- User/My account-->
        <v-menu offset-y
                left
                :close-on-content-click="true">

            <template v-slot:activator="{ on }">
                <v-btn text v-on="on" @click="dropdown = !dropdown">
                    <v-avatar size="32px" tile>
                        <img src="../../../public/img/userIcons/001-ninja.png"
                             alt="MWW">
                    </v-avatar>
                    <v-icon v-if="!dropdown"> arrow_drop_down</v-icon>
                    <v-icon v-else> arrow_drop_up</v-icon>
                </v-btn>
            </template>
            <!-- User menu drop down-->
            <v-card width="255">
                <!--header section-->
                <v-list class="cktoolbar">
                    <v-list-item @click="" class="nohover">
                        <v-list-item-avatar icon>
                            <img class="elevation-1" src="../../../public/img/userIcons/001-ninja.png" alt="DevTeam">
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title> {{user.firstName}} {{user.lastName}} </v-list-item-title>
                            <v-list-item-subtitle>{{user.subscriptionType}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                </v-list>
                <v-divider></v-divider>

                <!--/header section-->
                <v-list dense>
                    <template v-for="item in account">
                        <v-list-item v-if="item.switch === 'switch'" :key="item.title" @click="item.func">
                            <v-list-item-avatar>
                                <v-icon :class="[item.iconClass]">{{ item.icon }}</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="darkModeSwitch"></v-switch>
                                </v-list-item-action>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-else="item.switch === ''" :key="item.title" @click="item.func">
                            <v-list-item-avatar>
                                <v-icon :class="[item.iconClass]">{{ item.icon }}</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>

                            </v-list-item-content>
                        </v-list-item>

                    </template>
                    <v-divider></v-divider>

                    <v-list-item v-for="item in mwwmenu" :key="item.title" @click="item.func">
                        <v-list-item-avatar>
                            <v-icon :class="[item.iconClass]">{{ item.icon }}</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                            <v-list-item-subtitle>{{ item.subtitle }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
            <!--end of user menu drop down-->

        </v-menu>
        <!-- /User/My account-->
    </v-app-bar>
    
    <!-- /End Of Top Nav -->

    <!-- Project body-->
    <v-main>
        <!--<v-container fluid class="noPadding" style="height: 100%;">
        <v-layout justify-center align-center class="noPadding" style="height: 100%;">-->

        <v-container fluid class="noPadding" style="height: 100%;overflow: auto;padding-bottom: 50px;">

            <!-- Content Area -->
            <transition name="fade" mode="out-in">
                <router-view style="overflow-y: auto; height: 100%" v-bind:drawer="drawer">

                </router-view>
            </transition>
            <!-- /End of Content Area -->

        </v-container>
        <!--</v-layout>
    </v-container>-->
        <v-container class="hidden-md-and-up">
            <BottomNav></BottomNav>
        </v-container>
    </v-main>
    <!-- /Project body-->

    <!--footer section-->
    <!--<v-footer color="blue" >
    <v-layout justify-center align-center>
      <div>
        <label class="white--text" style="padding: 0 15px">&copy; {{ new Date().getFullYear() }}</label>
        <span> | </span>
        <label class="white--text" style="padding: 0 15px">Created By: <a href="http://codekonstruct.com/" target="_blank">CodeKonstruct</a></label>
        <span> | </span>
        <label class="white--text" style="padding: 0 15px">Version: {{version}}</label>
      </div>
    </v-layout>
  </v-footer>
      -->
    <!--footer section-->
  </v-app>

</template>

<script>

  import { sharedJS } from '../../components/dashboard/sharedJS.js'
  import { auth } from '../../auth/index.js'

    import pjson from '../../../package.json'
    import { isNullEmptyOrUndefined } from '../../javascript/globalFunctions'
    import { auditManager } from '../../javascript/auditManager'

  export default {
    components: {
      BottomNav: () => import('../../components/dashboard/bottomNav.vue'),
      SideNav: () => import('../../components/dashboard/sideNav.vue')
    },
    /// Added vm to create a Component Instance that can be called later
    data( vm ) {
      return {

        dropdown: false,
        drawer: false,

        mainMenuBadge: '',
        extensionsBadge: '3',
        budgetDataBadge: '1',
        notificationBadge: '2',

        darkModeSwitch: true,
        //topNav: '',
        //itemCard: '',

          

        version: '',
        
        //profile navigation
        account: [
          { icon: 'account_box', title: 'Manage Profile', func: vm.profilePage },
          //{ icon: 'payment', title: 'Payments', func: '' },
          { icon: 'settings', title: 'Settings', func: vm.showSettings },
          //{ icon: 'devices', title: 'Devices', func: '' },
          { icon: 'brightness_medium', title: 'Dark mode', func: vm.darkMode, switch: 'switch' },
          { icon: 'exit_to_app', title: 'Sign out', func: vm.signOut }
        ],
        mwwmenu: [
          { icon: 'help', title: 'help', func: vm.helpPage },
          { icon: 'feedback', title: 'Suggestions', func: vm.suggestionsPage },
          { icon: 'bug_report', title: 'Report', func: vm.reportPage }
        ],
        // END: profile navigation

        recent_notifications: [
          { icon: 'report_problem', iconClass: 'red lighten-1 white--text', title: 'You are over your budget', subtitle: '1 hour ago' },
          { icon: 'fa-exclamation-circle', iconClass: 'warning lighten-1 white--text', title: 'Your balance is low', subtitle: '2 hours ago' }
        ],
        quicklaunch: [
          { icon: 'fa-money-bill-alt', iconClass: 'red--text', subtitle: 'Expense' },
          { icon: 'fa-money-check-alt', iconClass: 'green--text', subtitle: 'Income' },
          { icon: 'fa-hand-holding-usd', iconClass: 'blue--text', subtitle: 'Transaction' },
          { icon: 'insert_chart', iconClass: 'teal--text', subtitle: 'Budget' },
        ]

      }
        },
        computed: {
            user() {

                let user = {
                    firstName: "Dev",
                    lastName: "Team",
                    subscriptionType: "Free"
                }

                if (this.$store.state.User.length === 0) {
                    return user
                }

                if (!isNullEmptyOrUndefined(this.$store.state.User)) {
                    return this.$store.state.User
                }

                return user
            }
        },
    methods: {
      transferPush: function () {
        this.$router.push({ path: '/dashboard/transaction' })
      },
      helpPage: function () {
        this.$router.push({ path: '/dashboard/help' })
      },
      suggestionsPage: function () {
        this.$router.push({ path: '/dashboard/suggestions' })
      },
      reportPage: function () {
        this.$router.push({ path: '/dashboard/issues' })
      },
      darkMode: function () {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark
        this.adjustDarkMode()

        this.$store.commit('UpdateNightModeSelection', this.darkModeSwitch)
      },
      adjustDarkMode: function () {
        this.darkModeSwitch = this.$vuetify.theme.dark

        this.$store.state.NightMode.topNav = this.$vuetify.theme.dark ? 'dark-mode' : 'light-mode'
        this.$store.state.NightMode.sideNav = this.$vuetify.theme.dark ? 'dark-mode' : 'light-mode'
        this.$store.state.NightMode.itemCard = this.$vuetify.theme.dark ? 'itemCarddark' : 'itemCardlight'

      },
      profilePage: function () {
        this.$router.push({ path: '/dashboard/Profile' })
      },
      showSettings: function () {
        this.$router.push({ path: '/dashboard/settings' })
        },

        reloadPageIfGetFails: function () {
            if (auth.checkAuth() && this.$store.state.Accounts.length === 0) {
                window.location.reload(false)
            }
        },
      signOut: function () {

        auth.logout()

        this.$router.push({ path: '/' })
      }
      
    },
    beforeMount() {

      //Get Side Nav Items From SharedJS
      this.items = sharedJS.mainMenuItems().items

      this.version = pjson.version 

      this.$vuetify.theme.dark = this.$store.state.NightMode.switch

      this.adjustDarkMode()

      ///////////////////////////////////////////////
      //TODO: This works but is hella stupid
      //      Fix in the future
      //From: johnoy
      ///////////////////////////////////////////////
        setTimeout(this.reloadPageIfGetFails, 2000)

        auditManager.getNewAuditsIfAny()

        auditManager.checkForAuditEveryFiveMinutes()
    },
    beforeCreate() {
        this.$store.commit('initialiseStore')

      ///////////////////////////////////////////////////////////////////
      //TODO: Check if online then run function below
      ///////////////////////////////////////////////////////////////////

      //this.$store.dispatch('syncStoreWithDatabaseAfterComingOnline')
    }
  }
</script>

<style>
  .noPadding > .v-toolbar__content, .v-toolbar__extension{
    padding: 0 !important;
  }

  .fixedToolbar {
    position: fixed !important;
    margin-top:48px !important;
  }

  .padContent {
    padding-top: 100px;
  }

  .padContentLess{
    padding-top: 50px;
  }

  .padContentMin{
      padding-top:20px;
  }

  .noHeight > .v-input__control{
    height: 0 !important;
  }

  .maxWidthHeight{
    width: 100%;
    height: 100%;
  }

  .padLeft{
      padding-left: 260px;
  }

 /*Overwrites vuetify rounded */
  .rounded {
    border-radius: 8px !important;
  }

    .transparentBackground {
        background-color: transparent !important;
    }

</style>


