import { store } from '../store/storeMain'

export const auditManager = {

    checkForAuditEveryFiveMinutes() {
        setInterval(this.getNewAuditsIfAny, 1020000)
    },

    getNewAuditsIfAny() {
        let dbAudits = store.dispatch('getAuditDataFromAPI')

        if (dbAudits.length > 1) {
            this.checkIfAuditFromDbMatchesStore(dbAudits)
        }
    },

    checkIfAuditFromDbMatchesStore(audits) {
        let storeAudits = store.state.Audit

        if (storeAudits.length === 0) {
            if (audits.length === 1) {
                store.commit('addNewAuditToStore', audits)
            } else if (audits.length > 1) {
                for (let i = 0; i < audits.length; i++) {
                    store.commit('addNewAuditToStore', audits[i])
                }
            }
        }
        else if (storeAudits.length > 0) {
            this.checkIfDBAuditExistsInStore(storeAudits, audits)
        }
    },

    checkIfDBAuditExistsInStore(storeAudits, audits) {
        for (let i = 0; i < audits.length; i++) {
            if (storeAudits.filter(x => x === audits[i]).length === 0) {
                this.getDataForMissedAuditFromDB(audits[i])
            }
        }
    },

    getDataForMissedAuditFromDB(audit) {

    },


}